import { createApp } from 'vue';
import { createPinia } from "pinia";
import App from './App.vue';
import router from './router';
import AppLayout from './layouts/AppLayout.vue';
import LoginComponent from './components/LoginComponent.vue';
import AdminMenuComponent from './components/AdminMenuComponent.vue';
import FileImport from './components/Import/FileImport.vue';
import ImportPreview from './components/Import/ImportPreview.vue';
import ImportFinish from './components/Import/ImportFinish.vue';
import ReviewRecord from './components/Import/ReviewRecord.vue';
import PrimeVue from 'primevue/config';
import Avatar from 'primevue/avatar';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Badge from 'primevue/badge';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmPopup from 'primevue/confirmpopup';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import DropDown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Fieldset from 'primevue/fieldset';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import Menubar from 'primevue/menubar';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ProgressSpinner from 'primevue/progressspinner';
import ProgressBar from 'primevue/progressbar';
import SplitButton from 'primevue/splitbutton';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import Toast from 'primevue/toast';
import Toolbar from 'primevue/toolbar';
import Textarea from 'primevue/textarea';
import ToastService from 'primevue/toastservice';
import Timeline from 'primevue/timeline';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Tooltip from 'primevue/tooltip';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
const app = createApp(App)
    .use(createPinia());
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
app.use(router);
// PrimeVUE components
app.component('Avatar', Avatar);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Card', Card);
app.component('ConfirmPopup', ConfirmPopup);
app.component('Chips', Chips);
app.component('DropDown', DropDown);
app.component('Dialog', Dialog);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('InputMask', InputMask);
app.component('Checkbox', Checkbox);
app.component('Button', Button);
app.component('Badge', Badge);
app.component('TabMenu', TabMenu);
app.component('Menubar', Menubar);
app.component('Menu', Menu);
app.component('Message', Message);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Panel', Panel);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('SplitButton', SplitButton);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('Timeline', Timeline);
// Application custom components
app.component('AppLayout', AppLayout);
app.component('LoginComponent', LoginComponent);
app.component('AdminMenuComponent', AdminMenuComponent);
app.component('FileImport', FileImport);
app.component('ImportPreview', ImportPreview);
app.component('ImportFinish', ImportFinish);
app.component('ReviewRecord', ReviewRecord);
app.directive('tooltip', Tooltip);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
router.isReady().then(() => {
    // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
    app.mount('#app');
});
